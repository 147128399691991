import { useRef, useState } from "react";

export default function SplashScreen() {
    const [showWidgetFallBack, setShowWidgetFallBack] = useState(true);

    const widgetClosePopup = () => {
        window.parent.postMessage({ iframe_destruct: true }, "*");
        setShowWidgetFallBack(false);
    };
    const widgetCloseRef = useRef(null);

    return (
        <>
            {showWidgetFallBack && (
                <div
                    className="fixed top-0 left-0 z-50 w-screen h-screen bg-[white] bg-opacity-50 "
                    style={{ backdropFilter: 'blur(10px)' }}
                >
                    <div className="flex justify-end p-3">
                        <svg
                            className="popCloseHeaderSvg w-[18px] h-[18px] cursor-pointer transition-all duration-300 hover:scale-125"
                            onClick={widgetClosePopup}
                            ref={widgetCloseRef}
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M16.726 15.3879C17.0913 15.7531 17.0913 16.3577 16.726 16.7229C16.5371 16.9118 16.2977 17 16.0584 17C15.819 17 15.5797 16.9118 15.3907 16.7229L8.5 9.83349L1.6093 16.7229C1.42034 16.9118 1.18099 17 0.941645 17C0.702297 17 0.462949 16.9118 0.27399 16.7229C-0.0913301 16.3577 -0.0913301 15.7531 0.27399 15.3879L7.16469 8.49843L0.27399 1.609C-0.0913301 1.24375 -0.0913301 0.639192 0.27399 0.27394C0.639311 -0.0913132 1.24398 -0.0913132 1.6093 0.27394L8.5 7.16336L15.3907 0.27394C15.756 -0.0913132 16.3607 -0.0913132 16.726 0.27394C17.0913 0.639192 17.0913 1.24375 16.726 1.609L9.83531 8.49843L16.726 15.3879Z"
                                style={{
                                    fill: "#da2036",
                                }}
                            />
                        </svg>
                    </div>
                    <div className="h-full flex justify-center items-center">
                        <span className="flex h-20 w-20 animate-pulse items-center justify-center">

                            <Spinner />
                        </span>

                    </div>
                </div>
            )
            }
        </>

    )
}

const Spinner = () => (
    <span className="w-12 h-12 border-4 border-[#da2036] border-b-transparent rounded-full inline-block box-border animate-spin"></span>
);